@import '../colors.scss';

.global-search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;

  .window {
    width: 90%;
    flex: 0 0 auto;
    width: 90%;
    max-width: 700px;
    padding: 20px;

    overflow-x: hidden;
  }
  .query {
    position: absolute;
    top: 25%;
    left: 0%;
    right: 0;
    width: 75%;
    display: flex;
    align-items: center;
    font-size: 1.5em;
    border: 1px solid $primary;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.8);
    padding: 10px 20px;
    background-color: #fff;
    max-width: 700px;
    margin: 0 auto;
  }
  i {
    font-size: 0.8em;
    flex: 0 0 auto;
    color: $primary;
    padding-right: 0.5em;
  }
  input {
    outline: none;
    border: 0;
    font-size: 1em;
    flex: 1 1 auto;
  }
  .results {
    margin-top: 1em;
    background-color: #fff;
    border-radius: 5px;
    overflow: hidden; //hide content going over the corners

    ul {
      margin: 0;
      padding: 0;
    }
    li {
      padding: 10px 20px;
      list-style: none;
    }
    li:hover {
      background-color: lighten($primary-light, 5);
    }
    li.selected {
      background-color: $primary-light;
    }
    .search-help-text {
      text-align: center;
      color: $primary;
    }
    .search-help-text:hover {
      background-color: #fff;
    }
  }
}

@media (max-width:600px){
  .query{
    font-size: 1.2em;
  }

  .results{
    font-size: 0.9em;
  }
}