@import '../colors.scss';

.add-company {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .container {
    display: flex;
    flex: 0 0 600px;
    min-height: 300px;
    border: 2px solid $primary;
    align-items: center;
    border-radius: 3px;

    .col {
      flex: 0 1 50%;
      padding: 1em;
    }
  }

  .error {
    flex: 0 0 100%;
    margin: 10px 0;
    padding: 5px;
    background-color: $red-background;
    border: 1px solid $red-border;
    color: $red-text;
    border-radius: 3px;
  }

  .icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .fa {
      font-size: 5em;
      color: $primary;
    }
  }
  .inputs {
    font-size: 1.1em;
    display: flex;
    flex-direction: column;

    .flex-row {
      display: flex;
      margin: 10px 0;
      align-items: center;
    }
    .label {
      flex: 0 0 100px;
      font-weight: 800;
    }
    .input {
      flex: 1 1 0;
      display: flex;
      input {
        width: 100%;
        font-size: 1em;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid $primary;
      }
    }
    button {
      height: 3em;
      padding: 10px;
      flex: 1 1 100%;
    }
  }
}

@media screen and (orientation: portrait) {
  .mobile .add-company {
    .container {
      flex-direction: column;
      height: auto;
      max-width: 90%;
      border: 0;
      flex: 0 1 500px;
    }
    .col.box-icon {
      flex: 1 0 200px;
    }
    .col {
      flex: 0 0 auto;
    }
    .inputs {
      width: 100%;
    }
  }
}
