$primary-darkest: rgb(30, 45, 61);
$primary-dark: rgb(26, 56, 84);
$primary: rgb(181, 193, 205);
$primary-medium: $primary;
$primary-light: rgb(222, 228, 232);
$primary-lightest: #f6f8f9;
$primary-text: lighten($primary-dark, 15);

$red: #d75452;
$red-border: lighten($red, 15);
$red-text: darken($red, 0);
$red-background: lighten($red, 32);
.red-text {
  color: $red-text;
}
.red-background {
  background-color: $red-background;
}

$green: rgb(79, 208, 79);
$green-border: lighten($green, 0);
$green-text: darken($green, 18);
$green-background: lighten($green, 32);

$orange: rgb(247, 169, 53);
$orange-border: lighten($orange, 0);
$orange-text: desaturate(darken($orange, 12), 10);
$orange-background: lighten($orange, 32);

$grey: #7d848a;
$grey-background: lighten($grey, 38);

$yellow: rgb(206, 167, 31);
$purple: rgb(132, 61, 197);

$pulse: pulse 0.5s infinite alternate cubic-bezier(0.6, 0.05, 0.8, 0.43);

$blue: rgb(0, 102, 197);
$seafoam: rgb(0, 197, 197);
$ochre: rgb(184, 197, 0);
$magenta: rgb(197, 0, 105);
$brightGreen: 	rgb(51,255,0);
