@import '../colors.scss';

.title2344 {
  padding-left: 20px;
  color: red;
}

.no-alarms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  svg {
    height: 12em;
  }
  h1 {
    padding-top: 1em;
    text-align: center;
    font-weight: 800;
  }

}

.textpg{
  padding-left: 20px;
  color: green;
}
.setting-input {
  margin-left: 10px;
}

.input {
  margin-left: 10px;
  width: 50px;
}
.label {
  margin: 0.2em;
}

.formst {
  border-style: solid;
  border-width: 1px;
  margin-left: 10px
}
.directory-top-bar {
  flex: 0 0 38px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $primary-light;
  max-width: 100%;
  padding-right: 20px;

  .show-inactive-toggle {
    margin-right: 1em;
  }
  .view-selector {
    display: flex;

    .view-option {
      padding-left: 15px;
      color: #000;
      cursor: pointer;
      opacity: 0.3;
      font-size: 0.9em;

      i {
        padding-right: 3px;
      }
    }
    .view-option:hover {
      opacity: 1;
    }
    .view-option.selected {
      opacity: 0.85;
    }
  }
  .breadcrumbs {
    list-style: none;
    margin: 0;
    padding: 0 0 0 30px;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    height: 2.5em;

    li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 0 1 auto;
      opacity: 0.5;
    }
    li:last-child {
      font-weight: 800;
      flex: 0 0 auto;
      opacity: 1;
    }
    li:hover {
      text-decoration: underline;
    }
    i {
      padding: 0 10px;
      opacity: 0.5;
    }
    .toggle-sidebar {
      display: none;
    }
    .root-directory-icon {
      width: 1.2em;
      height: 2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 10px;
    }
  }
}