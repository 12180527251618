.dropdown-container {
    position: relative;
    display: inline-block;
  
  .dropdown-menu {
    position: absolute;
    top: 40px;
    right: 0;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    min-width: 150px;
    z-index: 1000;
  }
  
  .dropdown-item {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: black;
    border-bottom: 1px solid #ddd;
  }
  
  .dropdown-item:last-child {
    border-bottom: none;
  }
  
  .dropdown-item:hover {
    background-color: #f0f0f0;
  }
}