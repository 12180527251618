@import '../colors.scss';

.landing.grid {
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  overflow-y: scroll;
  grid-gap: 5px;
  height: 100vh;

  .no-notifications {
    color: #888;
    font-style: italic;
    text-align: center;
  }
}

.device-card-container-small {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  max-height: 250px;  
  overflow-y: auto;

  .device-card-small {
    padding: 0;

    .box {
      padding: 0;
    }
  }
}

.box {
  box-sizing: border-box;
}
.banner.box {
  grid-column-start: 1;
  grid-column-end: 5;
  
  .content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-items: center;
    align-items: center;

    .sep {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
.alarm.box {
  grid-row-start: 2;
  grid-row-end: 2;
  grid-column-start: 1;
  grid-column-end: 3;
}

.alert.box {
  // grid-row-start: 2;
  // grid-row-end: 4;
  grid-column-start: 3;
  grid-column-end: 5;
}
.alert2.box {
  // grid-row-start: 2;
  // grid-row-end: 4;
  grid-column-start: 1;
  grid-column-end: 5;
}

.device.box {
  grid-row-start: 3;
  grid-row-end: 3;
  grid-column-start: 1;
  grid-column-end: 3;

  overflow: hidden;
  .small-content {
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.small-content-header {
  // display: grid;
  // grid-template-rows: .5fr .5fr;
  // grid-template-columns: 4fr 1fr;
  display: flex;
  justify-content: space-between;
  padding: 0 0 10px;
  align-items: center;
  border-bottom: 2px solid $primary-light;
  margin-bottom: 15px;
}

.small-content {
  overflow-y: auto;
  overflow-x: hidden;
  height: 250px;
}

.card-body{
  padding: 0px
}

.notification-list {
  list-style-type: none;
  padding: 0;
}


.mobile {
  .landing.grid {
    height: auto;
  }

  .device-card-container-small {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 1em 2em;
    flex: 1 1 100%;
  
    .none-available {
      text-align: center;
      font-style: italic;
      padding-top: 2em;
    }
  }

  .banner.box {
    grid-column-start: 1;
    grid-column-end: 5;
    
    .content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      justify-items: center;
      align-items: center;
  
      .tooltip {
        size: 5px;
      }

      h2 {
        font-size: small;
      }
      .sep {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .device.box {
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 1;
    grid-column-end: 3;

    min-height: 300px;
  }
  
  .device-card-small {
    .box {
      align-items: start;
      width: 100%;
    }
  }
}

.card-body {
  .modal-body {
    font-size: 16px;
    line-height: 1.6;
  }
}