@import '../colors.scss';

.permissions-paths {
  display: flex;
  flex-direction: column;
  max-height: 400px;
  margin-top: 2em;
  overflow-y: auto;

  padding: 10px;
  border: 1px solid lighten($primary, 12);
  border-radius: 3px;

  .title {
    flex: 0 0 auto;
    padding: 0 0 5px 0;
    font-weight: 800;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .show-inactive{
    font-weight: 400;
    float: right;
  }

  .include-inactive-checkbox{
    margin-right: 0.5em;
    margin-bottom: 1px;
    margin-top: 1px;
  }

  select {
    margin-left: 1em;
    width: auto;
  }
  .body {
    flex: 1 1 auto;
    overflow-y: auto;
    max-height: 100%;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .permissions-directory {
    // border-bottom: 1px solid lighten($grey, 20);
    height: 2em;
    display: flex;
    align-items: center;
  }
  input {
    margin-right: 0.8em;
  }
  input[disabled] {
    visibility: hidden;
  }
  button.remove-from-group {
    margin-right: 0.8em;
    float: right;
    max-height: 18px;
    padding: 0px;
  }
  li.group-name {
    padding: 5px;
    padding-left: 20px;
    border-bottom: 1px solid lighten($grey, 20);
  }
}
