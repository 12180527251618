.no-alarms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  svg {
    height: 12em;
  }
  h1 {
    padding-top: 1em;
    text-align: center;
    font-weight: 800;
  }
}


@import '../colors.scss';

.list-container {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .tasks-row {
    display: flex;
    align-items: center;
    flex: 0 0 30px;
    height: 3em;
    font-size: x-small;
    border-bottom: 1px solid #dee4e8;
  }
  .tasks-row.header {
    font-weight: 800;
    border-bottom: 2px solid $primary;
    flex: 0 0 40px;
  }
  .scrollable-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
}

    .group-right {
      font-size: small;
      flex: 0 0 200px;
      float: right;
    }

.device-list {
  .tasks-row:hover{
      background-color: #c4c4c4;
    }
  .tasks-row.header:hover{
      background-color: #fff;
    }
  .tasks-row {
    
    .tasks-id {
      flex: 1 1 0;
      border-bottom: none;
    }
    .tasks-voltage {
      flex: 1 1 0;
      border-bottom: none;
    }
    .tasks-name,
    .tasks-notes {
      flex: 2 2 0;
      border-bottom: none;
    }
    .tasks-last-message,
    .tasks-company {
      flex: 1.5 1.5 0;
      border-bottom: none;
    }
    .tasks-latest-reading,
    .tasks-sensor-type,
    .tasks-priority,
    .tasks-complete,
    .tasks-alerttype,
    .tasks-boardrev,
    .tasks-firmware,
    .tasks-filter-enabled,
    .tasks-summary {
      flex: 1 1 0;
      border-bottom: none;
    }
    .tasks-description {
      flex: 2 2 0;
      white-space: normal;
      max-height: 1em;
      border-bottom: none;
    }
  }
  .link,
  a {
    text-decoration: none;
  }
  .tasks-row > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
}

.device-list {
  .normal {
    padding-left: 3px;
  }
  .warning,
  .alarm,
  .inactive {
    display: inline-block;
    padding: 2px 3px;
    border-radius: 3px;
  }
  .warning {
    background-color: lighten($orange, 10);
    color: darken($orange, 45);
    border: 1px solid darken($orange, 10);
  }
  .alarm {
    background-color: lighten($red, 25);
    color: darken($red, 45);
    border: 1px solid darken($red, 10);
  }
  .inactive {
    border: 1px solid #666;
    background-color: #d8d8d8;
    color: #666;
  }
}
