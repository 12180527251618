@import '../../colors.scss';

.tag-graph {
  margin: 0 2% 20px 2%;
  flex: 1 1 100%;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;

  .toolbar {
    display: flex;
    align-items: center;
    flex: 0 0 auto;
    margin-bottom: 5px;

    button,
    .button {
      font-size: 0.75em;
      grid-row: 1;
      height: max-content;
      width: max-content;
      align-self: center;
    }

    .additional-data {
      display: flex;
      width: max-content;
      select{
        border-radius: 3px;
        font-size: 0.75em;
        height: 25px;
        color: gray;
      }
      option[value=""][disabled] {
      display: none;
      }
    }

    .temperature-slider {
      margin-left: 2em;
      width: max-content;
      display: flex;
      height: 25px
    }

    .group-left{
      flex: auto;
      display: grid;
      grid-gap: 10px;
      grid-auto-flow: column;
    }
    .group-right {
      flex: 0 0 200px;
    }
    .group-right {
      text-align: right;
    }
    .button-group {
      justify-content: flex-end;
    }
    .readout {
      flex: 1 1 50%;
      display: flex;
      justify-content: center;

      .time {
        flex: 0 0 auto;
        text-align: right;
        padding-right: 10px;
      }
      .reading {
        flex: 0 0 auto;
        text-align: left;
        padding-left: 10px;
        color: $yellow;
        font-weight: 800;
      }
      .reading.secondary {
        color: $blue;
      }
      .reading.tertiary {
        color: $seafoam;
      }
      .reading.fourth {
        color: $ochre;
      }
      .reading.fifth {
        color: $magenta;
      }
      .reading.sf6 {
        color: $brightGreen;
      }
    }
  }
  .tag-graph-body {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
  }
  .draggable {
    cursor: ns-resize;
  }

  #warning-tooltip{
    cursor: pointer;
    position: fixed;
    top: -50%;
    display: none;
    background: #fff;
    border: 1px solid $primary;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 0.85rem;
    font-weight: 400;
    width: 140px;
    line-height: 1.2em;
  }

  #fourier-plot{
    flex: 1 2 auto
  }

  #hibernation-tooltip{
    cursor: pointer;
    position: fixed;
    top: -50%;
    display: none;
    background: #fff;
    border: 1px solid $primary;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 0.85rem;
    font-weight: 400;
    width: 140px;
    line-height: 1.2em;
  }
}

.mobile {
  .tag-graph {
    .toolbar {
      display: none;
    }
  }
}
