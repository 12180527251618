@import './colors.scss';

html {
  -webkit-text-size-adjust: none;
}
/*prevent overscroll*/
html,
body {
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 11pt;
  background-color: #fff;
}

@media (prefers-color-scheme: dark) {
  body {
    filter: invert(100%) hue-rotate(180deg) brightness(120%) contrast(75%);
    background-color: #fff;
  }
  .layout-content,
  .login {
    background-color: #fff;
  }
  .login-picture {
    filter: invert(100%) hue-rotate(180deg) brightness(110%);
  }
}

h1,
h2 {
  color: $primary-dark;
  font-weight: 400;
  margin: 0;
}

h1 {
  font-size: 1.8rem;
}
h2 {
  font-size: 1.3rem;
}
p {
  line-height: 1.4;
}
hr {
  border-top: 1px solid $primary;
  border-bottom: none;
  margin: 0.5em 0;
}
button,
.button {
  padding: 5px 7px;
  display: inline-block;
  min-width: 1.25em;
  text-align: center;
  color: $grey;
  font-weight: normal;
  border-top: 1px solid $primary;
  border-right: 1px solid $primary;
  border-bottom: 1px solid $primary;
  border-left: 1px solid $primary;
  border-radius: 3px;
  background-color: #fff;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  outline: none;
  user-select: none;
  font-size: 0.85em;

  i {
    padding-left: 0.5em;
  }
  i:first-child {
    padding-left: 0;
  }
}
@media (hover: hover) {
  button:hover,
  .button:hover {
    color: $primary-dark;
    border-color: $primary-dark;
  }
}
button.primary,
.button.primary {
  background-color: $primary-dark;
  border-color: $primary-dark;
  color: #fff;
}
button.primary.red,
.button.primary.red {
  background-color: darken($red-border, 5);
  border-color: darken($red-border, 5);
  color: #fff;
}
@media (hover: hover) {
  button.primary:hover,
  .button.primary:hover {
    background-color: $primary-darkest;
    color: #fff;
  }
  button.primary.red:hover,
  .button.primary.red:hover {
    background-color: darken($red-border, 15);
    border-color: darken($red-border, 15);
    color: #fff;
  }
}
button.green,
.button.green {
  border-color: darken($green, 5);
  color: darken($green, 5);
}
@media (hover: hover) {
  button.green:hover,
  .button.green:hover {
    border-color: darken($green, 12);
    color: darken($green, 12);
  }
}
button.red,
.button.red {
  border-color: darken($red, 5);
  color: darken($red, 5);
}
@media (hover: hover) {
  button.red:hover,
  .button.red:hover {
    border-color: darken($red, 12);
    color: darken($red, 12);
  }
}
button.active,
.button.active {
  color: #fff;
  background-color: $primary;
  border-color: $primary;
}
@media (hover: hover) {
  button.active:hover,
  .button.active:hover {
    background-color: $primary-dark;
    border-color: $primary-dark;
  }
}
button.disabled,
button.disabled:hover,
.button.disabled,
.button.disabled:hover {
  background-color: lighten($grey, 20);
  color: $grey;
  border-color: #fff;
  cursor: not-allowed;
}
.button-group {
  white-space: nowrap;

  .button {
    border-left: 0;
    border-radius: 0;
  }
  @media (hover: hover) {
    .button:hover {
      border-left: 1px solid $primary-dark;
      margin-left: -1px;
    }
  }
  .button:first-child {
    margin-left: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-left: 1px solid $primary;
  }
  @media (hover: hover) {
    .button:first-child:hover {
      border-left: 1px solid $primary-dark;
    }
  }
  .button:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.badge {
  color: white;
  font-weight: 800;
  text-align: center;
  border-radius: 13px;
  background-color: $primary-dark;
  display: inline-block;
  padding: 2px 8px;
  font-size: 13px;
}
.space-badge {
  margin-top: 0.5em;
}
.tag {
  padding: 4px;
  border-radius: 3px;
  margin-right: 3px;
  font-size: 0.85em;
}

.badge.red {
  background-color: $red;
}
.badge.orange {
  background-color: $orange;
}
.badge.blue {
  background-color: $primary-dark;
}

a,
.link,
a:focus,
a:visited,
a:active {
  cursor: pointer;
  color: saturate(lighten($primary-dark, 20), 35);
  text-decoration: underline;
}
@media (hover: hover) {
  a:hover,
  .link:hover {
    color: lighten(saturate(lighten($primary-dark, 20), 35), 10);
  }
}
textarea {
  width: 100%;
  min-height: 75px;
  outline: none;
  border: 2px solid $primary-light;
  font-size: 1em;
  resize: none;
}
textarea:focus {
  border: 2px solid $primary;
}
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

select {
  width: 100%;
  padding: 5px;
  border: 1px solid $primary;
  background-color: #fff;
  height: 30px;
  font-size: 0.85em;
}
select:focus {
  outline: none;
  border-color: $primary-dark;
}
svg text {
  user-select: none;
}

.clickable {
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
}
.clickable:active,
.clickable:focus {
  outline: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.85em;
  th,
  td {
    height: 2.5em;
    margin: 0;
    text-align: left;
    padding: 0.5em 5px;
  }
  td {
    border-bottom: 1px solid $primary-light;
  }
  th {
    border-bottom: 2px solid $primary;
  }
}

table.single-row {
  table-layout: fixed;

  td {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  @media (hover: hover) {
    td:hover {
      overflow: visible;
      white-space: normal;
    }
  }
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.grid {
  padding: 1em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: 300px;
  grid-gap: 20px;
  flex: 1 1 auto;
}
.flex-grid {
  padding: 1em;
  display: flex;
}
.flex-grid > .box {
  flex: 1 1 0;
  height: 600px;
  margin: 0 10px;
}

.flex-grid > .box:first-child {
  margin-left: 0;
}
.flex-grid > .box:last-child {
  margin-right: 0;
}
.mobile {
  .flex-grid {
    flex-direction: column;
  }
  .flex-grid > .box {
    flex: 0 0 auto;
    margin: 10px 0;
    height: auto;
  }
  .flex-grid > .box:first-child {
    margin-top: 0;
  }
  .flex-grid > .box:last-child {
    margin-bottom: 0;
  }
}
.grid > .box,
.flex-grid > .box {
  display: flex;
  flex-direction: column;
  background: #fff;
  padding: 10px;
  border: 1px solid lighten($primary, 12);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}
.grid > .box > .title,
.flex-grid > .box > .title {
  flex: 0 0 auto;
  padding: 0 0 5px 0;
  font-weight: 800;
  display: flex;
  align-items: center;
  .left {
    font-size: 1.1em;
  }
  .right {
    text-align: right;
    flex: 1 1 100%;
    button,
    .button {
      margin-left: 0.5em;
    }
  }
  .left,
  .right {
    white-space: nowrap;
  }
}
.grid > .box > .body,
.flex-grid > .box > .body {
  flex: 1 1 100%;
  max-height: 100%;
  overflow-y: auto;
}
