@import '../colors.scss';

.menu-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;

  .menu {
    position: absolute;
    width: 150px;
    font-size: 0.9em;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid lighten($grey, 30);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);

    .menu-item {
      padding: 7px;
      cursor: pointer;
    }
    @media (hover: hover) {
      .menu-item:hover {
        background-color: $primary-lightest;
      }
    }
  }
}
