@import '../colors.scss';

.view-company-container {
  overflow-y: auto;
  width: 100%;
  flex: 1 1 auto;
  display: flex;
}
.view-company {
  width: 100%;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .row {
    flex: 0 0 auto;
    display: flex;

    .col {
      flex: 1 1 0;
    }
  }
  .permissions-row {
    padding: 10px;
  }
  label {
    font-weight: 800;
    display: block;
  }
  .value {
    padding: 0.5em 0 1em 1em;
  }
}

.view-company-delete {
  color: red;
  margin: 10px;
}

.view-company > .header {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  padding-bottom: 0.75em;
  margin-bottom: 1.5em;
  border-bottom: 1px solid $primary-dark;

  .name {
    color: $primary-darkest;
    font-size: 1.7em;
  }
  .spacer {
    flex: 1 1 auto;
  }
  .button {
    font-size: 0.85em;
    margin-left: 1em;
  }
}
.permissions-group {
  max-width: 225px;
  margin-bottom: -1em;
}
.permissions-options-list {
  padding-left: 3.25em;
  padding-top: 1em;
  i {
    width: 1.75em;
  }
  i.allowed {
    color: $green-text;
  }
  i.not-allowed {
    color: $primary;
  }
  .permissions-options-list-item {
    padding: 3px 0;
  }
}
