@import '../colors.scss';

.add-button-comp {
  background-color: $primary;
  color: $primary-dark;
  font-size: 1em;
  padding: 10px;
  margin: 15px 15px 0 15px;
  border: 0;
  flex: 0 0 auto;
}
.add-button-comp:hover {
  border: 0;
  background-color: darken($primary, 5);
}
