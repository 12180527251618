@import '../colors.scss';

.device-card-container {
  // display: flex;
  flex-direction: column;
  max-height: 100%;
  overflow-y: auto;
  padding: 1em 2em;
  flex: 1 1 100%;
  align-items: center;
  .none-available {
    text-align: center;
    font-style: italic;
    padding-top: 2em;
  }
}
.device-card-row {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  margin-bottom: 3em;
  margin-left: -10px;

  .show-dataless-devices-toggle {
    .box {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;
    }
  }
}
.device-card-row > .title {
  flex: 1 0 100%;
  font-size: 1.7em;
  color: $primary-dark;
  padding-bottom: 0.5em;
  margin-bottom: 0.5em;
  margin-left: 10px;
  border-bottom: 1px solid $primary-light;

  .path {
    list-style: none;
    margin: 0;
    padding: 10px 0 0 0;
  }
  .path li {
    display: inline;
  }
  .path li:before {
    content: '\f105';
    font-family: FontAwesome;
    padding: 0 10px;
    opacity: 0.5;
    font-weight: 900;
  }
  .path li:first-child:before {
    content: none;
    padding: 0;
    font-weight: 900;
  }
}

.mobile .device-card-row > .title {
  font-size: 1.4rem;
  font-weight: 800;
}
