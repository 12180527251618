.loading {
  opacity: .4;
  max-width: 50px;
}
.loading-container {
  flex: 1 1 auto;
  align-content: center;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: auto;
}