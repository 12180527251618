@import '../colors.scss';

.view-user {
  overflow-y: auto;
  width: 100%;
  flex: 1 1 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    font-weight: 800;
  }
  .divider {
    border-bottom: 1px solid $primary-light;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    width: 20em;
  }
  .last-active {
    color: #aaa;
    padding-top: 0.5em;
  }
  .row {
    max-width: 800px;
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    margin: 2em 0;

    .left,
    .right,
    .col {
      flex: 1 1 100%;
    }
  }
  .row.permissions {
    margin: -15px;
    padding: 15px;
    background-color: rgba($primary-light, 0.6);
    border-radius: 5px;
  }

  .buttons {
    button {
      margin-right: 0.5em;
    }
  }
  .yellow {
    color: $orange-text;
    opacity: 0.5;
  }
  .selected .yellow {
    opacity: 1;
  }
  h2 {
    margin-bottom: 0.5em;
  }
}

.mobile .view-user {
  .row {
    flex-wrap: wrap;
  }
  .divider {
    width: 100%;
  }
}
