@import '../colors.scss';

.add-device {
  flex: 1 1 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .container {
    display: flex;
    flex: 0 0 600px;
    height: 300px;
    border: 2px solid $primary;
    align-items: center;
    border-radius: 3px;

    .col {
      flex: 0 1 50%;
      padding: 1em;
    }
  }

  .box-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 100px;
    }
  }
  .icon {
    margin: 1em;
    width: 100px;
    height: 100px;
    background-color: #aaa;
    border-radius: 10px;
  }
  .inputs {
    font-size: 1.1em;
    display: flex;
    flex-direction: column;

    .flex-row {
      display: flex;
      margin: 10px 0;
    }
    .label {
      flex: 0 0 100px;
      font-weight: 800;
    }
    .input {
      flex: 1 1 0;
      display: flex;
      input {
        width: 100%;
        font-size: 1.1em;
        letter-spacing: 2px;
        padding: 5px;
        border-radius: 5px;
        border: 1px solid $primary;
      }
      // for the directory input
      .input-group {
        padding: 5px;
        border-radius: 5px;
        border: 1px solid $primary;
        height: auto;
      }
      // to make the menu appear over instead of under
      .directory-input {
        .browser {
          position: absolute;
          top: initial;
          bottom: 20px;
          left: 0;
        }
      }
    }
    .buttons {
      display: flex;

      .loading {
        height: 1.5em;
      }
      button {
        height: 3em;
        padding: 10px;
        flex: 1 1 100%;
      }
      button:nth-child(2) {
        margin-left: 0.5em;
      }
    }
  }
  .waiting-to-connect,
  .did-timeout,
  .is-online {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      font-weight: 800;
      font-size: 1.3em;
    }
    p {
      margin-bottom: 0;
      width: 100%;
    }
  }
  .waiting-to-connect {
    .loading {
      height: 70px;
    }
  }
  .did-timeout {
    .title,
    .fa {
      color: $orange;
    }
    .fa {
      font-size: 4em;
    }
  }
  .is-online {
    .fa,
    .title {
      color: $green-text;
    }
    .fa {
      font-size: 5em;
    }
  }
}
.add-device.step-0 {
  .flex-row.name,
  .flex-row.directory {
    visibility: hidden;
  }
}
.add-device.error {
  .flex-row.device-id,
  .flex-row.pin {
    input {
      background-color: $red-background;
    }
  }
}
.add-device.step-1 {
  .flex-row.device-id,
  .flex-row.pin {
    color: #aaa;
    input {
      background-color: $green-background;
      color: $green-text;
    }
  }
  .flex-row.name,
  .flex-row.directory {
    visibility: visible;
  }
}

@media screen and (orientation: portrait) {
  .mobile .add-device {
    .container {
      flex-direction: column;
      height: auto;
      max-width: 90%;
      border: 0;
      flex: 0 1 500px;
    }
    .col.box-icon {
      flex: 1 0 200px;
    }
    .col {
      flex: 0 0 auto;
    }
    .inputs {
      width: 100%;
    }
  }
}
