@import '../../colors.scss';

.data-table {
  overflow: hidden;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  .toolbar {
    display: flex;
    position: relative;
    padding: 15px 10px 0 10px;
    align-items: center;
    .spacer {
      flex: 1 1 100%;
    }
    .averages {
      font-weight: 400;
      padding-right: 1em;
      font-size: 0.8em;
      flex: 1 1 auto;
      white-space: nowrap;
    }
    .label {
      font-weight: 800;
      padding-left: 1em;
    }
    .date-range-overlay {
      position: fixed;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,0.2);
      backdrop-filter: blur(5px);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
    }
  }
  .date-range-picker{
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
    position: relative;
  }


  .download-data-menu {
    .menu-items {
      right: 0;
    }
    .menu-item {
      font-weight: 400;
    }
  }

  .table-container {
    flex: 1 1 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .row-header {
      font-weight: 800;
      border-bottom: 1px solid $primary;
      flex: 0 0 auto;
      display: flex;
      padding-bottom: 0.5em;
      border-bottom: 2px solid $primary-light;
      line-height: 2em;
    }
    .row {
      height: 30px;
      display: flex;
      align-items: center;
    }
    .row:nth-child(even) {
      background-color: $primary-lightest;
    }
    .row,
    .row-header {
      padding: 0 2em;
      .time,
      .reading {
        flex: 1 1 0;
        white-space: nowrap;
        padding-right: 1em;
      }
      .reading-center {
        flex: 1 1 0;
        padding-right: 1em;
        text-align: center;
      }
      .comment {
        flex: 2 2 0;
      }
    }
  }
}

.mobile {
  .data-table {
    .toolbar {
      display: none;
    }
  }
}
