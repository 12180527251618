@import '../colors.scss';

[class*='device-status-'] {
  border-radius: 3px;
  border-width: 1px;
  border-style: solid;
  padding: 2px 4px;
}

.device-status-1 {
  background-color: $primary-light;
  color: $primary-dark;
  border-color: $primary-dark;
}
