@import '../colors.scss';

.gauge {
  .danger {
    stroke: $red;
    stroke-width: 5px;
    fill: $red;
  }
  .danger-fill {
    fill: $red;
  }
  .danger-stroke {
    stroke: $red;
    stroke-width: 10px;
  }
}
.gauge.fa,
.gauge.fa-stack {
  font-size: 2em;
  color: #888;

  .fa-line-chart {
    font-size: 0.85em;
  }
}
