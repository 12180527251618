@import '../../colors.scss';

.tag-settings.grid {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  overflow-y: scroll;
}

.tag-settings-display.box {
}
.tag-settings {
  .input-group {
    flex: 0 0 auto;
    width: 30%;
  }
}

.mobile {
  .tag-settings.grid {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
  .tag-settings-display {
    flex: 0 0 auto;
    margin: 0 0 20px 0;
  }
}
