@import '../colors.scss';

.button-group {
  display: flex;
}
.button-group > .button-group-option {
  flex: 0 0 auto;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  margin: 0;
}
.button-group > .button-group-option:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.button-group > .button-group-option:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  border-right: 1px solid $primary;
}
.button-group > .button-group-option.selected {
  background-color: $primary-light;
  color: $primary-dark;
}
@media (hover: hover) {
  .button-group > .button-group-option:hover {
    border-left: 1px solid $primary;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
  }
}
