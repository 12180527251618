@import '../colors.scss';

.device-card {
  height: 220px;
  width: 210px;
  padding: 5px;

  .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    height: 100%;
    width: 100%;
    border-radius: 3px;
    transition: background-color 0.1s;

    .title {
      font-size: 1.1em;
      line-height: 1.5em;
      margin-bottom: 10px;
      max-width: 175px;
      display: flex;

      .device-name {
        flex: 1 1 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      flex: 0 0 auto;
    }
    .edit-device {
      flex: 1 1 auto;
      opacity: 0;
      width: 100%;
      transition: opacity 0.1s;
      display: flex;
      align-items: flex-end;

      .left {
        flex: 1 1 100%;
        color: darken($primary, 5);
      }
      .right {
        flex: 0 0 auto;
        a {
          color: darken($primary, 5);
        }
      }
    }
    .no-tags {
      font-size: 3em;
      flex: 1 1 100%;
      color: $primary-light;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@media (hover: hover) {
  .device-card .box:hover {
    background-color: lighten($primary-light, 5);

    .edit-device {
      opacity: 1;
    }
  }
}

.mobile {
  .device-card {
    height: auto;
    min-height: 107px;
    width: 100%;
    border-bottom: 1px solid $primary-light;
    .box {
      padding: 5px;
      .title {
        max-width: 100%;
        margin: 0;
      }
    }
    .edit-device {
      display: none;
    }
  }
  .device-card:last-child {
    border-bottom: 0;
  }
}

.device-card.delayed {
  .tooltip {
    .fa {
      color: $orange;
      padding-right: 0.5em;
      font-size: 1em;
    }
  }
  .tag-card {
    opacity: 0.5;
  }
}
