@import '../colors.scss';

.tooltip {
  position: relative;
  cursor: pointer;
  margin-left: 5px;
  i {
    color: $primary;
    font-size: 0.9rem;
  }
  .tooltip-text {
    position: absolute;
    top: -50%;
    display: none;
    background: #fff;
    border: 1px solid $primary;
    border-radius: 3px;
    padding: 5px 10px;
    font-size: 0.85rem;
    font-weight: 400;
    width: 140px;
    line-height: 1.2em;
  }
}
.tooltip:hover .tooltip-text {
  display: block;
}
