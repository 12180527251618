@import '../colors.scss';

.directory-top-bar {
  flex: 0 0 38px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $primary-light;
  max-width: 100%;
  padding-right: 20px;

  .show-inactive-toggle {
    margin-right: 1em;
  }
  .view-selector {
    display: flex;

    .view-option {
      padding-left: 15px;
      color: #000;
      cursor: pointer;
      opacity: 0.3;
      font-size: 0.9em;

      i {
        padding-right: 3px;
      }
    }
    .view-option:hover {
      opacity: 1;
    }
    .view-option.selected {
      opacity: 0.85;
    }
  }
  .breadcrumbs {
    list-style: none;
    margin: 0;
    padding: 0 0 0 30px;
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    height: 2.5em;

    li {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 0 1 auto;
      opacity: 0.5;
    }
    li:last-child {
      flex: 0 0 auto;
      opacity: 1;
    }
    li:hover {
      text-decoration: underline;
    }
    i {
      padding: 0 10px;
      opacity: 0.5;
    }
    .toggle-sidebar {
      display: none;
    }
    .root-directory-icon {
      width: 1.2em;
      height: 2em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 10px;
    }
  }
}

.mobile {
  .directory-top-bar {
    .show-inactive-toggle,
    .view-selector {
      display: none;
    }
  }
  .root-directory-icon {
    display: none;
  }
  .time-fetched {
    display: none;
  }
}

.time-fetched {
  padding-right: 2%;
}

.time-reload {
  margin-right: 1%;
}