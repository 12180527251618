@import '../colors.scss';
.modal-blackout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 10;

  //reset styles
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
}
.modal {
  flex: 0 1 500px;
  max-width: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  margin: 10px;

  .modal-body {
    padding: 10px;
    text-align: left;
  }
  .modal-title {
    font-size: 1.3em;
    font-weight: 800;
    color: $primary-dark;
    padding: 10px 10px 0 10px;
  }
  .modal-footer {
    text-align: right;
    padding: 10px 15px;
    border-top: 1px solid $primary-light;

    .button,
    button {
      margin-left: 1em;
    }
  }
  .button {
    padding: 10px 14px;
    font-size: 0.9em;
    min-width: 3.5em;
    border-radius: 3px;
    border: none;
    background-color: $primary-light;
    color: $primary-dark;
  }
  .button:hover {
    background-color: darken($primary-light, 3);
  }
  p {
    line-height: 1.4em;
  }
}
