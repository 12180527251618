@import '../../colors.scss';

.production-report {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .toolbar {
    flex: 0 0 auto;
    display: flex;
    justify-content: space-between;

    .options {
      display: flex;
      font-size: 0.8em;
      align-items: center;

      .label {
        padding: 0 0.5em 0 1em;
      }
      select {
        width: 10em;
      }
      input {
        display: inline-block;
        border: 1px solid $primary-light;
        border-radius: 2px;
        outline: none;
        font-size: 0.9em;
        padding: 8px;
        font-size: 0.85em;
        width: 5em;
        text-align: right;
      }
    }
  }
  .body {
    flex: 1 1 auto;
    max-width: 100%;
    overflow-y: auto;

    td,
    th {
      text-align: right;
    }
    th.date,
    td.date,
    th.comment,
    td.comment {
      text-align: left;
    }
    .highlight {
      background-color: darken($green, 20);
      color: white;
      padding: 5px;
      border-radius: 5px;
      margin-right: -5px;
    }
    td.comment {
      padding-left: 10px;
      width: 200px;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.4em;
    }
    .comment:hover {
      white-space: normal;
      overflow: visible;
      text-overflow: wrap;
    }
  }
}
