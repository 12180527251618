@import '../colors.scss';

.menu-component {
  position: relative;
  font-size: 0.9em;

  .menu-text,
  .menu-items {
    cursor: pointer;
    width: 100%;
    border-radius: 3px;
    z-index: 11;
  }
  .menu-text {
    display: flex;
    align-items: center;
    border: 1px solid $primary;
    padding: 7px;
    i {
      flex: 0 0 auto;
      display: none;
    }
    i.fa-caret-down {
      display: inline;
    }
    span {
      flex: 1 1 auto;
      text-align: center;
    }
  }
  @media (hover: hover) {
    .menu-text:hover {
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    }
  }
  .menu-items {
    position: absolute;
    top: 2.5em;
    background-color: #fff;
    border: 1px solid lighten($grey, 30);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
  }
  .menu-item {
    padding: 7px;
    cursor: pointer;
  }
  .menu-item:hover {
    background-color: $primary-lightest;
  }
  .menu-item.disabled {
    cursor: default;
    color: $primary;
  }
  .menu-separator {
    border-top: 1px solid $primary-light;
    margin: 0.25em 0;
    width: 100%;
  }
}
