.card-body {
  .notification-item {
      margin: 5px 0;
      padding: 8px;
      border: 1px solid #eee;
      border-radius: 4px;
      background-color: #f9f9f9;
      cursor: pointer;
    }
    
    .notification-item:hover{
      background-color: #555;
      transform: scale(1.01); 
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
    }
    
    .no-notifications {
      color: #888;
      font-style: italic;
      text-align: center;
    }

    .icon {
      font-size: 15px;
      margin-right: 10px;
    }  
    .update {
      color: #6a00ff;
    }
    
    .success {
      color: #4caf50;
    }

    .critical{
      color: #ff4d4f;
    }

    .info{
      color: #2196f3;
    }

    .warning{
      color: #ffc107;
    }
}