@import '../../colors.scss';

.tag-alarms {
  .center-text {
    text-align: center;
  }
  .extra-space {
    height: 2em;
  }
  overflow-y: scroll;
}

.tr {
  width: 100%;
}

.mobile {
  .tag-alarms.grid {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    overflow: visible;
  }
  .tag-alarms-log,
  .tag-alarms-actions,
  .tag-alarms-limits {
    flex: 1 0 auto;
    margin: 0 0 20px 0;
  }
}

.tooltip{
  .pending {
    animation: breathing 3s ease infinite normal;
    // padding-right: 0.75em;
    // padding-left: 0.75em;
    color: $orange;
  }
  .error {
    animation: breathing 3s ease infinite normal;
    color: $red;
  }

}
