.no-alarms {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  svg {
    height: 12em;
  }
  h1 {
    padding-top: 1em;
    text-align: center;
    font-weight: 800;
  }
}


@import '../colors.scss';

.list-container {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .manu-row {
    display: flex;
    align-items: center;
    padding: 0 1em;
    flex: 0 0 30px;
    font-size: small;
    border-bottom: 1px solid #dee4e8;
  }
  .manu-row.header {
    font-weight: 800;
    border-bottom: 2px solid $primary;
    flex: 0 0 40px;
  }
  .scrollable-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }
}

    .group-right {
      font-size: small;
      flex: 0 0 200px;
      float: right;
    }

.device-list {
  .manu-row {
    .manu-id,
    .manu-boardrev,
    .manu-firmware,
    .manu-startup-count,
    .manu-modem-restarts,
    .manu-battery-level,
    .manu-csq-value,
    .manu-signal-ratio {
      flex: 1 1 0;
      border-bottom: none;
    }

    .manu-time-online{
      flex: 1.3 1.3 0;
      border-bottom: none;
    }
  }
  .link,
  a {
    text-decoration: none;
  }
  .manu-row > * {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
}

.device-list {
  .normal {
    padding-left: 3px;
  }
  .warning,
  .alarm,
  .inactive {
    display: inline-block;
    padding: 2px 3px;
    border-radius: 3px;
  }
  .warning {
    background-color: lighten($orange, 10);
    color: darken($orange, 45);
    border: 1px solid darken($orange, 10);
  }
  .alarm {
    background-color: lighten($red, 25);
    color: darken($red, 45);
    border: 1px solid darken($red, 10);
  }
  .inactive {
    border: 1px solid #666;
    background-color: #d8d8d8;
    color: #666;
  }
}
