@import '../../colors.scss';

.address-input {
  display: flex;
  flex-direction: column;
  .line {
    display: flex;
    margin-bottom: 0.5em;
  }
  .contact-name,
  .street1,
  .street2 {
  }
  .city {
    flex: 1 1 auto;
    margin-right: 0.5em;
  }
  .state {
    flex: 0 0 5em;
    margin-right: 0.5em;
  }
  .zip {
    flex: 0 0 5em;
  }
}
