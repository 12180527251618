@import '../colors.scss';

@media (hover: hover) {
  .device-card:hover {
    .tag-card {
      border: 1px solid $primary;
    }
  }
}
.tag-card {
  background-color: #fff;
  cursor: pointer;
  padding: 5px;
  overflow: hidden;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 3px;
  border: 1px solid #0000;
  transition: transform 0.1s, border 0.1s;

  .gauge {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1 100%;
    min-height: 7vh;
    svg {
      max-height: 7vh;
      width: 100%;
    }
    .label {
      opacity: 0.5;
      font-size: 0.9em;
    }
  }
  .readout {
    display: flex;
    flex-direction: column;
    text-align: center;
    flex: 0 0 auto;
    max-width: 100%;

    .value {
      flex: 0 0 auto;
      font-weight: 800;
      font-size: 1.5em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
    .time {
      opacity: 0.5;
      font-size: 0.9em;
    }
  }
}
@media (hover: hover) {
  .tag-card:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transform: translateY(-1px);
  }
}
.tag-card.alarm-norm {
  .value {
    color: $green-text;
  }
}
.tag-card.alarm-low-high {
  .value {
    color: $red-text;
  }
}
.mobile .tag-card,
.tag-card.horizontal,
.mobile .tag-card.shrink {
  width: 100%;
  flex-direction: row;

  .gauge {
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 auto;

    svg {
      display: block;
      margin: 0;
      width: 70px;
      height: 70px;
      flex: 0 0 auto;
    }
    .label {
      text-align: right;
    }
  }
  .readout {
    display: flex;
    flex-direction: column;
    text-align: right;
    flex: 1 1 100%;
  }
}
.tag-card.shrink {
  flex-direction: row;
  .readout {
    flex: 1 1 100%;
    text-align: right;
  }
  .gauge {
    flex: 0 1 70px;
  }
}
