.a-table {
    border-collapse: 'collapse';
    width: '100%'
}

.top-class { 
    max-height: 700px; 
    overflow-y: auto;
}

.sub-column-header {
    text-align: center;
    border-left: 1px solid;
    -webkit-text-fill-color: rgb(176, 0, 229);
}

.sub-column-header2 {
    text-align: center;
    -webkit-text-fill-color: green;
}

.sub-column {
    position: relative;
    text-align: center;
    border-left: 1px solid;
    -webkit-text-fill-color: rgb(176, 0, 229);
}

.sub-column2 {
    position: relative;
    text-align: center;
    -webkit-text-fill-color: green;
}


.bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 0;
    opacity: 0.3;
  }
  
  .mobile-bar {
    background-color: rgb(176, 0, 229);
  }
  
  .desktop-bar {
    background-color: green;
    left: 10px;
  }
  
  .cell-content {
    position: relative;
    z-index: 1;
  }